.portal-signup {
  font-family: 'SF Pro Display';
  width: 100%;
  height: 50px;
  /* display: flex;
  flex-direction: row; */
}

.portal-signup-email {
  position: relative;
  display: inline-block;
  width: 100%;
  height: 100%;
  padding: 0.625rem 1.6rem;
  font-size: 0.875rem;
  font-weight: 400;
  line-height: 1.5;
  color: #000;
  background-color: #fff;
  background-clip: padding-box;
  border: 1px solid #F5F5F5;
  border-radius: 5px;
  box-shadow: 0 3px 2px rgb(233 236 239 / 5%);
}

.portal-signup-button {
  position: relative;
  top: -45px;
  left: calc(100% - 105px);
  display: inline-block;
  background-color: #C7B078;
  color: #353B40;
  border-radius: 4px;
  width: 100px;
  height: calc(100% - 10px);
  text-align: center;
  padding: 8px 0px 0px 0px;
  cursor: pointer;
}

.portal-signup-button:hover {
  background-color: #353B40;
  color: #FFFFFF;
}
